import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { configureSentry, ErrorBoundary } from '@picter/react-app-core';
import { ErrorPage } from '@picter/prisma';

import AppProvider from 'src/components/AppProvider';
import AppRouter from 'src/components/AppRouter';
import IdentifyUserTracker from 'src/containers/IdentifyUserTracker';
import IntercomChat from 'src/components/IntercomChat';
import PageViewTracker from 'src/components/PageViewTracker';
import MobileWarning from 'src/components/MobileWarning';
import CookieBanner from 'src/components/CookieBanner';
import Routes from 'src/routes';
import { areCookiesAccepted, acceptAllCookies } from 'src/utils/tracking/gtag';

configureSentry();

const App = () => {
  // Google Analytics tracking
  useEffect(() => {
    if (areCookiesAccepted()) {
      acceptAllCookies();
    }
  }, []);
  return (
    <div id="picter-app">
      <AppProvider>
        <AppRouter>
          <ErrorBoundary
            errorElement={
              <ErrorPage onClickAction={() => window.location.reload()} />
            }
          >
            <IdentifyUserTracker />
            <IntercomChat appId={process.env.REACT_APP_INTERCOM_APP_ID} />
            <PageViewTracker />
            <MobileWarning />
            <CookieBanner />
            <Routes />
          </ErrorBoundary>
        </AppRouter>
      </AppProvider>
    </div>
  );
};

render(<App />, document.getElementById('root'));
