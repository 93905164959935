import React, { lazy, Suspense } from 'react';
import { Spinner } from '@picter/prisma';
import { Switch, Route } from 'react-router-dom';
import { RouteUnauthenticated } from '@picter/cerberus-redux';

import paths from './paths';

const AppPage = lazy(() => import('src/pages/AppPage'));
const LoginRoute = lazy(() => import('src/routes/LoginRoute'));
const SignupRoute = lazy(() => import('src/routes/SignupRoute'));

const { APP_PATH, LOGIN_PATH, SIGNUP_PATH } = paths;

const Routes = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <RouteUnauthenticated path={LOGIN_PATH} component={LoginRoute} />
      <RouteUnauthenticated path={SIGNUP_PATH} component={SignupRoute} />
      <Route path={APP_PATH} component={AppPage} />
    </Switch>
  </Suspense>
);

export default Routes;
