/* eslint-disable no-nested-ternary */

/**
 * PATH Structure (PATH-structure)
 *
 * PATHs are created to be used with `Route` and `PageViewTracker` components.
 * Each PATH declared below has the following properties:
 *
 * name   - Name of the page/path described.
 * path   - A string that can contain one or multiple slash prepended words that
 *          form a part of an URL. Cannot be null.
 * scope  - Scope in which the page is in. Used in the final event name tracked.
 * track  - Boolean value that tells the tracking library to track or not. Based on name.
 */

/**
 * Creates a new PATH by extending from another one.
 *
 * @function
 * @param {Object/PATH-structure} parent
 *
 * @param {Object} data - The new PATH data.
 * @param {string} data.name - Name of the new PATH. If present the PATH will be tracked.
 * @param {string} data.path - Slash prepended path that will form an URL.
 * @param {string} data.scope - Word that will be included in the page view event.
 *
 * @param {Object} opts
 * @param {boolean} [opts.extendPath=true] - Set as false to prevent extending parent's path.
 * @param {boolean} [opts.extendScope=true] - Set as false to prevent extending parent's scope.
 *
 * @returns {Object/PATH-structure} Final PATH with extended path and scope.
 */
const extend = (
  parent,
  { name, path, scope } = {},
  { extendPath = true, extendScope = true } = {},
) => {
  const extendedPath = extendPath ? [parent.path, path].join('') : path;
  const extendedScope = [];

  if (extendScope && parent.scope) {
    extendedScope.push(parent.scope);
  }

  if (scope) {
    extendedScope.push(scope);
  }

  return {
    name,
    path: extendedPath,
    scope: extendedScope.length !== 0 ? extendedScope.join(' / ') : null,
    track: !!name, // only paths that declare a name prop will be tracked
  };
};

// PATH: To use with `Route` component

/*
  ========================================
  BASE PATHS
  ========================================
*/
const APP = {
  path: '',
  scope: 'App',
  track: false,
};
// App / Viewed Home
export const HOME = extend(APP, {
  name: 'Home',
  path: '/',
});
// dashboard path is used for redirection to contests only
export const DASHBOARD = extend(APP, {
  path: '/dashboard',
});

/*
  ========================================
  AUTHENTICATION PATHS
  ========================================
*/
const AUTH = extend(
  APP,
  {
    path: '',
    scope: 'Auth',
  },
  { extendScope: false },
);
export const LOGIN = extend(AUTH, {
  path: '/login',
  name: 'Login',
  scope: 'Login',
});
export const SIGNUP = extend(AUTH, {
  path: '/signup',
  name: 'Sign up',
  scope: 'Sign up',
});

/*
  ========================================
  CONTESTS PATHS
  ========================================
*/
// Not tracked because it doesn't contain the name property
const CONTESTS = extend(
  APP,
  {
    path: '/contests', // /contests
    scope: 'Contests',
  },
  {
    extendScope: false,
  },
);
// Contests / Open & upcoming / Viewed Open & upcoming
export const CONTESTS_OPEN = extend(CONTESTS, {
  path: '', // /contests
  name: 'Open & upcoming',
  scope: 'Open & upcoming',
});
// Contests / Closed / Viewed Closed
export const CONTESTS_CLOSED = extend(CONTESTS, {
  path: '/closed', // /contests/closed
  name: 'Closed',
  scope: 'Closed',
});
// Contests / Submitted / Viewed Submitted
export const CONTESTS_SUBMITTED = extend(CONTESTS, {
  path: '/submitted', // /contests/submitted
  name: 'Submitted',
  scope: 'Submitted',
});
export const CONTESTS_START_SUBMISSION = extend(CONTESTS, {
  path: '/:callId/start', // /contests/:callId/start
  name: 'Start submission',
});

/*
  ========================================
  LIBRARY AND PROJECT PATHS
  ========================================
*/
const LIBRARY = extend(APP, {
  path: '',
  scope: 'Library',
});
// Projects /
export const LIBRARY_PROJECTS = extend(
  LIBRARY,
  {
    path: '/projects',
    scope: 'Projects',
  },
  { extendScope: false },
);
export const LIBRARY_PROJECTS_EMPTY = extend(LIBRARY_PROJECTS, {
  path: '/empty',
  name: 'Empty state',
});
export const LIBRARY_IMAGES = extend(LIBRARY, {
  path: '/images',
  name: 'Image browser',
});

// Projects / Project / Viewed Project
export const LIBRARY_PROJECT = extend(LIBRARY_PROJECTS, {
  path: '/:projectId',
  name: 'Project',
  scope: 'Project',
});
// Projects / Project / Info / Viewed Info
export const LIBRARY_PROJECT_INFO = extend(LIBRARY_PROJECT, {
  path: '/info',
  name: 'Info',
  scope: 'Info',
});
// Projects images
export const LIBRARY_PROJECT_IMAGES = extend(
  LIBRARY_PROJECT,
  {
    path: '/images',
    scope: 'Projects images',
  },
  { extendScope: false },
);
export const LIBRARY_PROJECT_IMAGE = extend(LIBRARY_PROJECT_IMAGES, {
  path: '/:imageIndex',
  name: 'Image',
});
export const LIBRARY_PROJECT_IMAGE_INFO = extend(LIBRARY_PROJECT_IMAGE, {
  path: '/info',
  name: 'Info',
  scope: 'Info',
});

/*
  ========================================
  SUBMISSION PATHS
  ========================================
*/
export const SUBMISSIONS = extend(
  APP,
  {
    path: '/submission/:callId',
    scope: 'My submission',
  },
  { extendScope: false },
);
export const SUBMISSIONS_SUMMARY = extend(SUBMISSIONS, {
  path: '/summary',
  name: 'Submission summary',
  scope: 'Summary',
});
export const SUBMISSIONS_INTRODUCTION = extend(SUBMISSIONS, {
  path: '/introduction',
  name: 'Introduction',
  scope: 'Introduction',
});
export const SUBMISSIONS_PROFILE = extend(SUBMISSIONS, {
  path: '/profile',
  name: 'Profile',
  scope: 'Profile',
});
export const SUBMISSIONS_PRECONDITION = extend(SUBMISSIONS, {
  path: '/precondition',
  name: 'Precondition',
  scope: 'Precondition',
});
export const SUBMISSIONS_COORDINATOR_PROFILE = extend(SUBMISSIONS, {
  path: '/coordinator-profile',
  name: 'Coordinator profile',
  scope: 'Coordinator profile',
});
export const SUBMISSIONS_UPLOAD = extend(SUBMISSIONS, {
  path: '/upload',
  name: 'Upload',
  scope: 'Upload',
});
export const SUBMISSIONS_DESCRIPTIONS = extend(SUBMISSIONS, {
  path: '/descriptions',
  name: 'Description',
  scope: 'Description',
});
export const SUBMISSIONS_SUBMIT = extend(SUBMISSIONS, {
  path: '/submit',
  name: 'Submit',
  scope: 'Submit',
});
export const SUBMISSIONS_SUCCESS = extend(SUBMISSIONS, {
  path: '/success',
  name: 'Success',
  scope: 'Success',
});

/*
  ========================================
  SUBMISSION PROJECT PATHS
  ========================================
*/
// this path is only being used to check if we are inside a project route
export const SUBMISSIONS_PROJECT = extend(SUBMISSIONS, {
  path: '/projects/:projectId',
  scope: 'Project',
});
export const SUBMISSIONS_PROJECT_UPLOAD = extend(SUBMISSIONS_PROJECT, {
  path: '/upload',
  name: 'Upload',
});
export const SUBMISSIONS_PROJECT_DESCRIPTIONS = extend(SUBMISSIONS_PROJECT, {
  path: '/descriptions',
  name: 'Description',
});

/*
  ========================================
  SUBMISSION COORDINATOR PATHS
  ========================================
*/
export const SUBMISSIONS_COORDINATOR_ENTRIES = extend(SUBMISSIONS, {
  path: '/coordinator-entries',
  name: 'Coordinator entries',
  scope: 'Coordinator entries',
});
export const SUBMISSIONS_COORDINATOR_ENTRIES_PROFILE = extend(
  SUBMISSIONS_COORDINATOR_ENTRIES,
  {
    path: '/profile/:userId',
    name: 'Profile',
    scope: 'Profile',
  },
);
export const SUBMISSIONS_COORDINATOR_ENTRIES_UPLOAD = extend(
  SUBMISSIONS_COORDINATOR_ENTRIES,
  {
    path: '/new-project/:userId',
    name: 'Upload',
    scope: 'Upload',
  },
);
export const SUBMISSIONS_COORDINATOR_ENTRIES_PROJECT = extend(
  SUBMISSIONS_COORDINATOR_ENTRIES,
  {
    path: '/project/:projectId',
    name: 'Project',
    scope: 'Project',
  },
);
export const SUBMISSIONS_COORDINATOR_ENTRIES_DESCRIPTIONS = extend(
  SUBMISSIONS_COORDINATOR_ENTRIES_PROJECT,
  {
    path: '/descriptions',
    name: 'Descriptions',
  },
);

/*
  ========================================
  LEGACY PATHS
  ========================================
*/

/*
  SUBMISSION LEGACY PATHS
  ========================================
*/
export const SUBMISSIONS_LEGACY = extend(APP, {
  path: '/submissions/:submissionId',
  scope: 'Submissions legacy',
});

/*
  CALL LEGACY PATHS
  ========================================
*/
export const CALLS_LEGACY = extend(APP, {
  path: '/calls',
  scope: 'Calls',
});
export const CALLS_LEGACY_CREATE = extend(CALLS_LEGACY, {
  path: '/:callId/create-submission',
  scope: 'Call create submission',
});

/*
  ADDITIONAL FILES PATHS
  ========================================
*/
export const ADDITIONAL_FILES = extend(APP, {
  path: '/additional-files/:requestId',
  name: 'Additional files',
  scope: 'Additional files',
});

export const ADDITIONAL_FILES_SUCCESS = extend(ADDITIONAL_FILES, {
  path: '/success',
  name: 'Success',
});
