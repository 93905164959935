/* global gtag */

import Cookies from 'js-cookie';

import { COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES } from './constants';

export const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;

export function areCookiesAccepted() {
  return Cookies.get(COOKIE_CONSENT_COOKIE_NAME) === COOKIE_VALUES.ALL;
}

function createFunctionWithTimeout(callback, timeout) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, timeout || 1000);
  return fn;
}

export function acceptAllCookies() {
  gtag('consent', 'update', {
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
}

export function acceptFunctionalCookies() {
  gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/screens
export function screenView({ screenName }) {
  gtag('event', 'screen_view', {
    screen_name: screenName,
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export function pageView(url) {
  gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function event({
  action,
  category,
  label,
  value,
  callback,
  adSlot,
  mediaType,
  nonInteraction = false,
}) {
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    non_interaction: nonInteraction,
    event_callback: callback ? createFunctionWithTimeout(callback) : undefined,
    // Custom dimension Ad Slot (dimension 1)
    // https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters#cd_
    // https://support.google.com/analytics/answer/2709828?hl=en#zippy=%2Cin-this-article
    ad_slot: adSlot,
    // Custom dimension Media Type (dimension 1)
    media_type: mediaType,
  });
}
