import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Cookies from 'js-cookie';
import { Icon, A, Button, P } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import {
  COOKIE_CONSENT_COOKIE_NAME,
  COOKIE_VALUES,
} from 'src/utils/tracking/constants';
import {
  acceptAllCookies,
  acceptFunctionalCookies,
} from 'src/utils/tracking/gtag';

const FixedContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    bottom: 0;
    right: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 12px;
  background-color: ${props => props.theme.colors.primary.default};
  width: auto;
  max-width: 450px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 30%), 0 2px 2px 0 rgb(0 0 0 / 6%);

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 100%;
    max-width: 100%;
    z-index: 2000;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

// Set correct domain on production, beta, review and staging environments.
// otherwise subdomains like app.picter.com will be used by default (which
// makes the cookie invisible on other subdomains of picter.com).
function getDomain(host) {
  const match = /(picter\.(?:com|io))/.exec(host);
  if (match) {
    return match[1];
  }
  return undefined;
}

export default function CookieBanner() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const cookieConsent = Cookies.get(COOKIE_CONSENT_COOKIE_NAME);
    if (cookieConsent === undefined) {
      setShow(true);
    }
  }, []);

  const acceptAll = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES.ALL, {
      expires: 730,
      domain: getDomain(window.location.hostname),
    });
    acceptAllCookies();
    setShow(false);
  }, []);

  const acceptFunctional = useCallback(() => {
    Cookies.set(COOKIE_CONSENT_COOKIE_NAME, COOKIE_VALUES.FUNCTIONAL, {
      expires: 14,
      domain: getDomain(window.location.hostname),
    });
    acceptFunctionalCookies();
    setShow(false);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <FixedContainer>
      <FlexContainer>
        <Flex>
          <Icon type="info_outline" color="white" />

          <P color="white" style={{ marginLeft: 10 }}>
            May we use cookies?{' '}
            <A
              style={{ textDecoration: 'underline' }}
              color="white"
              href="https://www.picter.com/cookie-and-opt-out-advice"
            >
              Learn more
            </A>
          </P>
        </Flex>
        <ButtonContainer>
          <Button size="small" onClick={acceptFunctional}>
            Functional cookies only
          </Button>
          <Button
            size="small"
            colorScheme="blacknwhite"
            light
            onClick={acceptAll}
          >
            Accept all cookies
          </Button>
        </ButtonContainer>
      </FlexContainer>
    </FixedContainer>
  );
}
