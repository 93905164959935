import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { configureAnalytics } from '@picter/react-app-core';
import googleAnalytics from 'react-ga';

import browserHistory from 'src/utils/browser-history';

configureAnalytics();
browserHistory.listen(location => googleAnalytics.pageview(location.pathname));

const AppRouter = ({ children }) => (
  <Router history={browserHistory}>{Children.only(children)}</Router>
);

AppRouter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppRouter;
