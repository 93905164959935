import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux-immutable';
import {
  belongsTo,
  hasMany,
  reducers as jsonApiReducers,
  jsonApiMiddleware,
} from '@picter/torii';
import { authentication, user } from '@picter/cerberus-redux';

const { jsonApiData, jsonApiRequests } = jsonApiReducers({
  schemas: {
    projectAccesses: {
      project: belongsTo('projects', {
        inverse: 'projectAccesses',
      }),
    },
    projectImages: {
      project: belongsTo('projects', {
        inverse: 'images',
      }),
      additionalFiles: hasMany('files', {
        inverse: 'image',
      }),
    },
    files: {
      image: belongsTo('projectImages', {
        inverse: 'additionalFiles',
      }),
    },
    projects: {
      images: hasMany('projectImages', {
        inverse: 'project',
      }),
      projectAccesses: hasMany('projectAccesses', {
        inverse: 'project',
      }),
      submission: belongsTo('submissions', {
        inverse: 'projects',
      }),
    },
    submissions: {
      projects: hasMany('projects', {
        inverse: 'submission',
      }),
      invoice: belongsTo('invoices', {
        inverse: 'submission',
      }),
      profiles: hasMany('submissionProfiles', {
        inverse: 'submission',
      }),
    },
    submissionProfiles: {
      submission: belongsTo('submissions', {
        inverse: 'profiles',
      }),
    },
    imageMetaData: {
      images: belongsTo('images', {
        inverse: 'project',
      }),
    },
    invoices: {
      submission: belongsTo('submissions', {
        inverse: 'invoice',
      }),
    },
    users: {
      businessProfile: belongsTo('businessProfiles', {
        inverse: 'user',
      }),
    },
    businessProfiles: {
      user: belongsTo('users', {
        inverse: 'businessProfile',
      }),
    },
  },
});

const reducers = combineReducers({
  authentication,
  api: jsonApiData,
  request: jsonApiRequests,
  user,
});

const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk,
      jsonApiMiddleware({
        baseUrl: `${process.env.REACT_APP_API_URL}/app-content`,
      }),
    ),
    /* eslint-disable no-underscore-dangle */
    process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f,
    /* eslint-enable no-underscore-dangle */
  ),
);

export default store;
